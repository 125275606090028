import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { getToken, clearToken, getForbidToken, clearForbidToken, getModules, getIdentity, clearModules, clearIdentity, getMqttToken, clearMqttToken, getCustomAuth, clearCustomAuth, getCustomModule, clearCustomModule } from '@iso/lib/helpers/utility';
import actions from './actions';
import corpActions from '../corporateAccount/actions';
// import dashBoardAction from '../dashboard/actions';
import { get, fetch, post, putOtp } from '@iso/lib/helpers/resApiRequestor';
import { pushDataToApp } from '@iso/lib/helpers/mobileAppCommKit';
import * as cookie from 'js-cookie';

const history = createBrowserHistory();
const apiUrl = process.env.REACT_APP_API_URL;
const systemId = process.env.REACT_APP_SYSTEM_ID;
let headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    try {
      yield put({
        type: actions.CLEAR_ERROR,
      });
      yield put({
        type: actions.SET_LOADING,
        loading: true,
      });
      const apiResult = yield login(payload);
      if (apiResult) {
        const result = apiResult.data ? apiResult.data : apiResult;
        if (result.success) {
          const authValue = result.data.user.role.authorizations;
          let formattedAuthorization = [];
          let formattedModuleId = {};
          for (let i = 0; i < authValue.length; i++) {
            const element = authValue[i];
            formattedModuleId[element.moduleId] = element;
          }
          formattedAuthorization.push(formattedModuleId);

          const moduleResult = yield fetchModules(result.data.token);
          let modResult = moduleResult.data;
          if (modResult.success) {
            let formattedModule = [];
            let formatteElement = {};
            let modulesData = modResult.data.result;
            for (let i = 0; i < modulesData.length; i++) {
              const element = modulesData[i];
              formatteElement[element.alias] = element;
            }
            formattedModule.push(formatteElement);
            yield put({
              type: actions.SUBMODULES,
              payload: modResult.data.result,
            });
            yield put({
              type: corpActions.RESET_CORP_WALLETS_DETAILS,
            });
            cookie.set('token', result.data.token)
            yield put({
              type: actions.LOGIN_SUCCESS,
              token: result.data.token,
              identity: JSON.stringify(result.data.user),
              modules: JSON.stringify(modResult.data.result),
              customAuthorizations: JSON.stringify(formattedAuthorization[0]),
              customModules: JSON.stringify(formattedModule[0]),
              profile: 'Profile',
            });
            yield put({
              type: actions.SET_LOADING,
              loading: false,
            });
          }
        } else {
          let errorResponse = '';
          if (result && result.message === 'Unauthorized') {
            errorResponse = 'INVALID_AUTH';
          } else if (result && result.message === 'Account Locked') {
            errorResponse = 'ACCOUNT_LOCKED';
          } else if (result && result.message === 'Password Expired') {
            errorResponse = 'PASSWORD_EXPIRED';
          } else {
            errorResponse = 'Login Error';
          }
          yield put({
            type: actions.LOGIN_ERROR,
            error: errorResponse,
          });
          yield put({
            type: actions.SET_LOADING,
            loading: false,
          });
        }
      } else {
        console.log('CHECK ERRORA', apiResult);
        yield put({
          type: actions.LOGIN_ERROR,
          error: 'INVALID_AUTH',
        });
        yield put({
          type: actions.SET_LOADING,
          loading: false,
        });
      }
    } catch (e) {
      console.log('CHECK ERRORB', e);
      yield put({
        type: actions.LOGIN_ERROR,
        error: 'Login Error',
      });
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
    }
    // } catch (e) {
    //   console.log(e.message)
    //   e.message.indexOf('Network') > -1 ? console.log('NETOWORK ERROR') : console.log('LOGIN ERROR')
    //   yield put({
    //     type: actions.LOGIN_ERROR,
    //     error: e.message.indexOf('Network') > -1 ? 'NETWORK_ERROR' : 'INVALID_AUTH',
    //   });
    //   yield put({
    //     type: actions.SET_LOADING,
    //     loading: false,
    //   });
    // }
  });
}
export function* signupRequest() {
  yield takeEvery('SIGNUP', function* ({ payload }) {
    yield put({
      type: actions.CLEAR_ERROR,
    });
    yield put({
      type: actions.SET_LOADING,
      loading: true,
    });
    // const token = false;
    try {
      const apiResult = yield signup(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.SET_LOADING,
          loading: false,
        });
        yield put({
          type: actions.CLEAR_ERROR,
        });
        window.location.href = result.url;
      } else {
        yield put({
          type: actions.SIGNUP_ERROR,
          error: `Registration failed. ${result.message}`,
        });
        yield put({
          type: actions.SET_LOADING,
          loading: false,
        });
      }
    } catch (e) {
      console.log(e)
      yield put({
        type: actions.SIGNUP_ERROR,
        error: 'Registration failed',
      });
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
    }
  });
}

export function* forgotPassword() {
  yield takeEvery('FORGOT_PASSWORD', function* ({ email }) {
    yield put({
      type: actions.CLEAR_ERROR,
    });
    yield put({
      type: actions.SET_LOADING,
      loading: true,
    });
    // const token = false;
    try {
      const apiResult = yield forgotPasswordReq(email);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FORGOT_ERROR,
          error: null,
        });
        yield put({
          type: actions.SET_LOADING,
          loading: false,
        });
        yield put({
          type: actions.FORGOT_PASSWORD_DONE,
          success: true,
        });
      } else {
        yield put({
          type: actions.FORGOT_PASSWORD_DONE,
          success: false,
        });
        yield put({
          type: actions.FORGOT_ERROR,
          error: result.data,
        });
        yield put({
          type: actions.SET_LOADING,
          loading: false,
        });
      }
    } catch (e) {
      console.log(e)
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
    }
  });
}

export function* requestLoginOtp() {
  yield takeEvery('REQUEST_LOGIN_OTP', function* (payload) {
    try {
      yield put({
        type: actions.REQUESTING_LOGIN_OTP,
      });
      yield put({
        type: actions.KICKED_OUT_CLEAR,
      });
      yield put({
        type: actions.CLEAR_ERROR,
      });
      yield put({
        type: actions.SET_LOADING,
        loading: true,
      });
      const apiResult = yield requestingLoginOtp(payload.payload);
      if (apiResult) {
        const result = apiResult.data;
        if (result.success) {
          if (!result.is_agree_otp) {
              yield put({
                type: actions.SET_OTP_BANNER,
                otp_banner: true,
                otp_email: result.email,
                user_id: result.user_id,
              });
              yield put({
                type: actions.SET_LOADING,
                loading: false,
              });
          } else {
            const message = `OTP successfully sent to ${result.data.email}`;
            yield put({
              type: actions.REQUESTED_LOGIN_OTP,
              otpData: result,
              otpModal: true,
              otpMessage: message,
              otpModule: 'Login'
            });
            yield put({
              type: actions.SET_LOADING,
              loading: false,
            });
          }
          // SAVE THE TOKEN OF THE USER FOR REQUEST USED
          yield put({
            type: actions.OTP_AUTH_TOKEN,
            payload: result.token,
          });
        } else {
          let errorResponse = '';
          if (result && result.message === 'Unauthorized') {
            errorResponse = 'INVALID_AUTH';
          } else if (result && result.message === 'Account Locked') {
            errorResponse = 'ACCOUNT_LOCKED';
          } else if (result && result.message === 'Password Expired') {
            errorResponse = 'PASSWORD_EXPIRED';
          } else {
            errorResponse = 'Login Error';
          }
          yield put({
            type: actions.LOGIN_ERROR,
            error: errorResponse,
          });
          yield put({
            type: actions.SET_LOADING,
            loading: false,
          });
          yield put({
            type: actions.ERROR_REQUEST_LOGIN_OTP,
          });
        }
      } else {
        yield put({
          type: actions.LOGIN_ERROR,
          error: 'INVALID_AUTH',
        });
      }
    } catch (error) {
      yield put({
        type: actions.ERROR_REQUEST_OTP,
      });
    }
  });
}

export function* agreeOtp() {
  yield takeEvery('SAVE_AGREE_OTP', function* ({payload}) {
      try {
        yield put({
          type: actions.SAVING_AGREE_OTP,
        });
        const userId = payload.userId;
        const state = yield select();
        const token = state.Auth.otpAuthToken;
        const apiResult = yield saveAgreeOtp(userId, payload, token);
        const result = apiResult.data;   
        console.log('SAVE_AGREE_OTP_RESULT', result);     
        if (result) {
          yield put({
            type: actions.SAVED_AGREE_OTP,
            payload: result.data,
          });
          const state = yield select();
          const loginData = state.Auth.loginData;
          // console.log('LOGIN_DATA', loginData);
          yield put({
            type: actions.REQUEST_LOGIN_OTP,
            payload: loginData,
          });
        } else {
          yield put({
            type: actions.SAVE_AGREE_OTP_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.SAVING_AGREE_OTP,
          message: 'Error in saving agree otp!'
        });
      }
  });
}

function saveAgreeOtp(id, data, token) {
  // console.log('ID', id);
  // console.log('data', data);
  // console.log('token', token);
  return putOtp(`onetimepassword/save_agree_otp/${id}`, data, token);
}


export function* verifyOtp() {
  yield takeEvery('VALIDATE_OTP', function* ({payload}) {
    try {
      yield put({
        type: actions.VALIDATING_OTP,
      });
      const state = yield select();
      const otpData = state.Auth.otpData;
      const loginData = state.Auth.loginData;
      const verifyPayload = {
        username: loginData.username,
        password: loginData.password,
        otp: payload.otp,
        token: payload.token,
      };
      const apiResult = yield verifyingOtp(verifyPayload);
      const result = apiResult.data;
      // console.log('validate_otp_result', result);
      if (apiResult) {
        if (result.success) {
          yield put({
            type: actions.VALIDATED_OTP,
            payload: result,
            isErrorVerifyOtp: false,
            verifyOtpMessage: result.message,
            otpModal: false,
          });
              yield put({
                type: actions.LOGIN_REQUEST,
                payload: loginData,
              });
            yield put({
              type: actions.CLEAR_OTP_DATA,
            });
        } else {
          let closeModal = true;
          let limitReachMessage = null;
          if (payload.otpAttempts > otpData.otp_attempts) {
            closeModal = false;
            limitReachMessage = true;
            result.message = null;
          }
          yield put({
            type: actions.VALIDATED_OTP,
            payload: result,
            verifyOtpMessage: result.message,
            isErrorVerifyOtp: true,
            otpModal: closeModal,
            limitReachMessage,
          });
        }
      }
    } catch (error) {
      yield put({
        type: actions.CLEAR_OTP_DATA,
      });
    }
  });
}

export function* resendLoginOtp() {
  yield takeEvery('RESEND_LOGIN_OTP', function* () {
    try {
      yield put({
        type: actions.RESENDING_LOGIN_OTP,
        payload: true,
        isErrorVerifyOtp: false, 
      });
      const state = yield select();
      // const token = state.Auth.otpAuthToken;
      const resendData = state.Auth.loginData;
      const apiResult = yield resendingOtp(resendData);
      const result = apiResult.data;
      // console.log('RESEND_OTP_RESULT', result);
      if (result.success) {
        // console.log('RESULT', result);
        const message = `OTP successfully sent to ${result.data.email}`;
        yield put({
          type: actions.REQUESTED_LOGIN_OTP,
          otpData: result,
          otpMessage: message,
          otpModal: true,
        });
        yield put({
          type: actions.RESENDING_LOGIN_OTP,
          payload: false,
          isErrorVerifyOtp: false,
        });
      } else {
        yield put({
          type: actions.RESEND_LOGIN_OTP_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.RESEND_LOGIN_OTP_FAILED,
      });
    }
  });
}
// export function* changeMerchant() {
//   yield takeEvery('CHANGE_MERCHANT', function* ({ payload }) {
//     const state = yield select();
//     const role = state.Auth.identity.role.role.label;
//     const token = state.Auth.idToken;
//     const merchantId = role !== 'Administrator' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : payload.id;
//     const storesResult = yield fetchStores(token, merchantId);
//     console.log('STORES_VALUE_REQ', storesResult);
//     if (storesResult.status === 200 || storesResult.status === 204) {
//       yield put({
//         type: actions.CHANGE_MERCHANT_SUCCESS,
//         selectedMerchant: payload.id,
//         selectedMerchantName: payload.name,
//         stores: storesResult.data ? JSON.stringify(storesResult.data) : null,
//       });
//     }
//   });
// }

// export function* resendRegistrationEmail() {
//   yield takeEvery('RESEND_REGISTRATION_EMAIL', function* ({ payload }) {
//     try {
//       yield put({
//         type: actions.SENDING_REGISTRATION_EMAIL,
//       });
//       const apiResult = yield resendSuccesRegEmail(payload);
//       console.log('CHECK RESULT', apiResult);
//       if (apiResult.status === 200) {
//         yield put({
//           type: actions.SENT_REGISTRATION_EMAIL,
//           payload: 'Email successfully sent',
//         });
//       } else {
//         yield put({
//           type: actions.RESEND_REGISTRATION_EMAIL_FAILED,
//           payload: 'Failed to send email',
//         });
//       }
//     } catch (e) {
//       yield put({
//         type: actions.RESEND_REGISTRATION_EMAIL_FAILED,
//         payload: 'Failed to resend email',
//       });
//     }
//   });
// }

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem('idToken', payload.token);
    yield localStorage.setItem('modules', payload.modules);
    yield localStorage.setItem('identity', payload.identity);
    // yield localStorage.setItem('stores', payload.stores);
    // yield localStorage.setItem('topic', payload.topic);
    // yield localStorage.setItem('env', payload.env);
    yield localStorage.setItem('customAuthorizations', payload.customAuthorizations);
    yield localStorage.setItem('customModules', payload.customModules);
    // yield localStorage.setItem('roles', payload.roles);
    // yield localStorage.setItem('merchantList', payload.merchantList);
    // yield localStorage.setItem('selectedMerchant', payload.selectedMerchant);
    // yield localStorage.setItem('selectedMerchantName', payload.selectedMerchantName);
    pushDataToApp('idToken', payload.token);
    pushDataToApp('modules', payload.modules);
    pushDataToApp('identity', payload.identity);
    // pushDataToApp('stores', payload.stores);
    // pushDataToApp('topic', payload.topic);
    // pushDataToApp('env', payload.env);
    pushDataToApp('customAuthorizations', payload.customAuthorizations);
    pushDataToApp('customModules', payload.customModules);
    // pushDataToApp('roles', payload.roles);
    // pushDataToApp('merchantList', payload.merchantList);
    // pushDataToApp('selectedMerchant', payload.selectedMerchant);
    // pushDataToApp('selectedMerchantName', payload.selectedMerchantName);
    yield put({
      type: actions.RESET_SECOND_TIMER,
      secondTimer: 1800,
    });
  });
}

// export function* changeMerchantSuccess() {
//   yield takeEvery(actions.CHANGE_MERCHANT_SUCCESS, function* (payload) {
//     yield localStorage.setItem('selectedMerchant', payload.selectedMerchant);
//     yield localStorage.setItem('selectedMerchantName', payload.selectedMerchantName);
//     yield localStorage.setItem('stores', payload.stores);
//     pushDataToApp('selectedMerchant', payload.selectedMerchant);
//     pushDataToApp('selectedMerchantName', payload.selectedMerchantName);
//     pushDataToApp('stores', payload.stores);
//     yield put({
//       type: actions.RESET_SECOND_TIMER,
//       secondTimer: 1800,
//     });
//   });
// }

export function* subModuleLoad() {
  yield takeEvery(actions.SUBMODULES, function* (data) {
    yield localStorage.setItem('modules', typeof data.payload === 'string' ? JSON.parse(data.payload) : data.payload);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () { });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield clearToken();
    yield clearModules();
    yield clearIdentity();
    // yield clearStores();
    // yield clearTopic();
    // yield clearEnv();
    yield clearMqttToken();
    yield clearCustomAuth();
    yield clearCustomModule();
    yield clearForbidToken();
    // yield clearRoles();
    // yield clearMerchantList();
    // yield clearSelectedMerchant();
    // yield clearSelectedMerchantName();
    yield put({
      type: actions.RESET_SECOND_TIMER,
      secondTimer: 0,
    });
    history.push('/');
  });
}
// export function* kickedOutClear() {
//   yield takeEvery(actions.KICKED_OUT_CLEAR, function* () {
//     yield clearForbidToken();
//   });
// }
export function* checkAuthorization() {

  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const forbidToken = getForbidToken().get('forbidToken');
    const token = getToken().get('idToken');
    const modules = getModules().get('modules');
    const identity = getIdentity().get('identity');
    // const stores = getStores().get('stores');
    // const topic = getTopic().get('topic');
    // const env = getEnv().get('env');
    const mqttToken = getMqttToken().get('mqttToken');
    const customAuthorizations = getCustomAuth().get('customAuthorizations');
    const customModules = getCustomModule().get('customModules');
    // const roles = getRoles().get('roles');
    // const merchantList = getMerchantList().get('merchantList');
    // const selectedMerchant = getSelectedMerchant().get('selectedMerchant');
    // const selectedMerchantName = getSelectedMerchantName().get('selectedMerchantName');
    // console.log('TOKEN_CHECK', token);
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        identity,
        modules,
        // stores,
        profile: 'Profile',
        // topic,
        // env,
        customAuthorizations,
        customModules,
        // roles,
        // merchantList,
        // selectedMerchant,
        // selectedMerchantName,
      });
      // console.log('TOKEN_CHECK_AFTER');
      yield put({
        type: actions.LOGIN_VALIDATOR,
        token: mqttToken,
      });
      // yield put({
      //   type: actions.SUBMODULES,
      //   payload: JSON.parse(modules),
      // });
    }
    if (forbidToken) {
      yield put({
        type: actions.KICKED_OUT,
      });
      yield put({
        type: actions.LOGOUT,
      });
    }
  });
}

export function* getRedirectUrl() {
  yield takeEvery('FETCH_REDIRECT_URL', function* () {
    // console.log('ENTER_SAGA 1');
    try {
      const apiResult = yield fetchRedirectUrl();
      const result = apiResult.data;
      // console.log('FETCH_URL', result);
      yield put({
        type: actions.FETCHING_REDIRECT_URL,
      });
      yield put({
        type: actions.FETCHED_REDIRECT_URL,
        redirectUrl: result.redirect_login,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_REDIRECT_URL_FAILED,
      });
      // console.log('FETCH_REDIRECT_URL_FAILED');
    }
  });
}

export function* getResetPassUrl() {
  yield takeEvery('FETCH_RESET_PASSWORD_URL', function* () {
    // console.log('ENTER_SAGA 1');
    try {
      const apiResult = yield fetchResetPassUrl();
      const result = apiResult.data;
      // console.log('ENTER_SAGA 3', result);
      yield put({
        type: actions.FETCHING_RESET_PASSWORD_URL,
      });
      yield put({
        type: actions.FETCHED_RESET_PASSWORD_URL,
        resetPassUrl: result,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_RESET_PASSWORD_URL_FAILED,
      });
      // console.log('FETCH_REDIRECT_URL_FAILED');
    }
  });
}
function requestingLoginOtp(data) {
  return post(`onetimepassword/otp/request`, data);
}
function verifyingOtp(data) {
  return post(`onetimepassword/otp/validate`, data);
}
function resendingOtp(data) {
  return post(`onetimepassword/otp/resend_otp`, data);
}
function login(data) {
  // this.checkToken();
  console.log('LOGIN', data)
  return fetch(`${apiUrl}authenticate`, {
    headers: headers,
    method: 'POST',
    data,
  });
}

function signup(data) {
  // this.checkToken();
  return fetch(`${apiUrl}registrationbizaccounts/pre_register`, {
    headers: headers,
    method: 'POST',
    data,
  });
}

function fetchRedirectUrl() {
  console.log('ENTER_SAGA 2', apiUrl);
  return fetch(`${apiUrl}resettokens/get_reset_password_url`, {
    headers: headers,
    method: 'get',
  });
}

function fetchModules(token) {
  // this.checkToken();
  return get(`system/${systemId}/modules`, null, null, null, token);
}

// function fetchStores(token, mid) {
//   // this.checkToken();
//   return get(`stores/ui/get_store_by_mid/${mid}`, null, null, null, token);
// }

// function fetchAllRoles(token) {
//   // this.checkToken();
//   return get(`roles/fetch/store_roles`, null, null, null, token);
// }

function forgotPasswordReq(email) {
  // this.checkToken();
  return get(`/resettokens/get_id_by_email/${email}`);
}

// function fetchAllMerchant(token) {
//   // this.checkToken();
//   return get(`merchants/get_all_merchants`, null, null, null, token);
// }

// function resendSuccesRegEmail(data) {
//   return fetch(`${apiUrl}registrationbizaccounts/resend_email`, {
//     headers: headers,
//     method: 'POST',
//     data,
//   });
// }

function fetchResetPassUrl() {
  // console.log('ENTER_SAGA 2', apiUrl);
  return fetch(`${apiUrl}resettokens/get_reset_password_url`, {
    headers: headers,
    method: 'get',
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(signupRequest),
    fork(forgotPassword),
    fork(loginSuccess),
    fork(subModuleLoad),
    fork(loginError),
    fork(logout),
    fork(getResetPassUrl),
    fork(requestLoginOtp),
    fork(agreeOtp),
    fork(verifyOtp),
    fork(resendLoginOtp),
  ]);
}

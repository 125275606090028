import actions from './actions';

const initState = {
  resettingMerchantUserPassword: false,
  merchantPasswordResetData: null,
  newMerchantUserPasswordReset: false,
  resettingTokenData: false,
  hasResetTokenData: null,
  fetchingRedirectUrl: false,
  redirectUrl: null,
  unblockMerchant: null,
  isUnblock: false,
  isUnblockError: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.LOAD_MERCHANT:
      return initState;
    case actions.RESETTING_TOKEN_DATA:
      return {
        ...state,
        resettingTokenData: true,
      };
    case actions.RESET_TOKEN_DATA_SUCCESS:
      return {
        ...state,
        resettingTokenData: false,
        hasResetTokenData: action.result,
      };
    case actions.RESET_TOKEN_DATA_FAILED:
      return {
        ...state,
        resettingTokenData: false,
        hasResetTokenData: null,
      };
    case actions.NEW_RESETTING_MERCHANT_USER_PASSWORD:
      return {
        ...state,
        resettingMerchantUserPassword: true,
      };
    case actions.NEW_MERCHANT_USER_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        merchantPasswordResetData: action.result,
        newMerchantUserPasswordReset: true,
      };
    case actions.NEW_RESET_MERCHANT_USER_PASSWORD_RESET:
      return {
        ...state,
        newMerchantUserPasswordReset: true,
      };
    case actions.NEW_MERCHANT_USER_PASSWORD_RESET_FAILED:
      return {
        ...state,
        resetErrorMessage: `Couldn't reset password. Please contact Administrator`,
        resetError: true,
        resettingMerchantUserPassword: false,
        newMerchantUserPasswordReset: false,
      };

    //REDIRECT URL
    case actions.FETCH_REDIRECT_URL:
      return initState;
    case actions.FETCHING_REDIRECT_URL:
      return {
        ...state,
        fetchingRedirectUrl: true,
      };
    case actions.FETCHED_REDIRECT_URL:
      return {
        ...state,
        fetchingRedirectUrl: false,
        redirectUrl: action.redirectUrl,
      };
    case actions.FETCH_REDIRECT_URL_FAILED:
      return {
        ...state,
        fetchingRedirectUrl: false,
        redirectUrl: null,
      };
      case actions.UNBLOCKING_MERCHANT:
        return {
          ...state,
          isUnblock: true,
        };
      case actions.UNBLOCKED_MERCHANT:
        return {
          ...state,
          isUnblock: false,
          unblockMerchant: action.payload,
          isUnblockError: false,
        };
      case actions.UNBLOCK_MERCHANT_FAILED:
        return {
          ...state,
          isUnblock: false,
          isUnblockError: action.isUnblockError,
          unblockMerchant: action.payload,
        };
      case actions.RESET_UNBLOCK_MERCHANT:
        return {
          ...state,
          isUnblock: false,
          unblockMerchant: null,
          isUnblockError: false,
        };
    default:
      return state;
  }
}
